<template>
  <UDashboardLayout>
    <Navbar/>

    <div class="flex flex-col flex-1 w-full"> 

      <div>
        <MobileHeader v-if="width <= 1000"/>
        <NavHorizontale v-if="width > 1000"/>
      </div>
  
      <slot name="default"/>
        
      <footer class="container-page layoutFooter text-xs" v-if="showFooter">
        <div class="flex items-center gap-4 justify-between flex-1">
          <div class="flex gap-4 items-center">
            <ElementsIcon class="picto-tecrmi" icon="tecdoc-inside"/>
            <ElementsIcon class="picto-tecrmi" icon="tecrmi-inside"/>
            <NuxtLink class="legalLink" :to="links.legalMentions">Mentions légales</NuxtLink>
          </div>
          <p class="m-0">Powered by <NuxtLink class="legalLink" :to="links.autopartspro" target="_blank">©Autopartspro</NuxtLink> {{ new Date(Date.now()).getFullYear() }} - {{ version }}</p>
        </div>
        <p class="extraSmallText">Les données affichées ici, particulièrement la base de donnée complète, ne doivent pas être copiées. Il est interdit d’exploiter les données ou la base de données complète, de laisser un tiers les exploiter, ni de les rendre accessible à un tiers, sans accord préalable de TecAlliance. Toute infraction constitue une violation des droits d’auteur et fera l’objet de poursuites.</p>
      </footer>  
    </div>

    <BoxAddCartPopup class="popUp" :link="links.cart"/>

    <ModalsVehicleSearch/>
    <ModalsVehiclesSelect/>

    <ModalsTecRmiCgu/>

  </UDashboardLayout>
</template>

<script setup lang="ts">
import { useShopStore } from '~/store/useShopStore';
import { useUserStore } from '~/store/useUserStore';
import type { Group } from '~/types/shop';
const userStore = useUserStore();
const config = useRuntimeConfig()
const { establishment, version, links } = useAppConfig();
const userToken = useCookie(config.public.cookies.userToken);
const { width } = useWindowSize()
const showFooter = ref(false)

const headers = new Headers({
  'Authorization': `${userToken.value}`
})

const { data, error} = await useAsyncData('getUser' ,() => $fetch("/api/users/getUser?details=true", {
  headers: headers
}))

userStore.user = data.value

const { initializeCart } = useCart();

if(!userStore.user && !userStore.user?.customerEmail) await navigateTo(links.login);
await initializeCart(userStore.user.customerEmail , establishment.htSite, establishment.configuration.isConsigneIncluded);

onMounted(() => {
  showFooter.value = true
})

if(establishment.configuration.isShop) {
  const { data: productCat } = await useFetch<Group[]>('/api/shop/groups', { default: () => [] });
  const { setGroups } = useShopStore()  
  setGroups(productCat.value)
}
</script>

<style scoped>
.layout {
  flex:1;
  display: flex;
  flex-direction: column;
}
.picto-tecrmi {
  width: 6rem;
  max-width: 6rem;
  height: 1.5rem;
  max-height: 1.5rem;
  color: var(--neutral45);
}
.layoutFooter {
  color: var(--neutral71);
  background-color: transparent;
  font-family: var(--default-font-site);
  padding: 0;
  margin-top: 0;
  border-radius: 0;
}
.legalLink {
  text-decoration: none;
  color: var(--neutral71);
  white-space: nowrap;
}
.extraSmallText {
  font-size: .5rem;
  margin: 0;
  line-height: .5rem;
}

@media all and (max-width: 768px) {
  .layoutFooter > div {
    display: flex;
    flex-direction: column;
    gap:.2rem;
  }
}
</style>